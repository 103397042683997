import { defineStore, storeToRefs } from 'pinia';
import { computed, nextTick, Ref, ref, shallowRef, watch } from 'vue';
import { Note, NoteStatus } from '@/types/notes';
import { useAsyncState } from '@vueuse/core';
import services from '@/api/services';
import { useNotesFilters } from '@/stores/notes/useNotesFilters';

export const useNotesListStore = defineStore('notes-list', () => {
  const {
    initialNotes,
    notes,
    notesToTasks,
    totalNotes,
    isNotesLoading,
    fetchNotes,
  } = useNotes();

  const {
    initialCompletedNotes,
    completedNotes,
    fetchCompletedNotes,
    isCompletedNotesLoading,
  } = useCompletedNotes();

  const workloadPercentage = computed(() =>
    Math.min((totalNotes.value / 15) * 100, 100),
  );

  const updatePriorityNumbers = usePriorityNumbers(
    notes,
    initialNotes,
    notesToTasks,
  );

  const { toggleStatus, isNoteMoving } = useToggleStatus();

  function useToggleStatus() {
    const movingNotes = shallowRef<Note[]>([]);

    const isNoteMoving = computed(
      () => (id: number) => !!movingNotes.value.find((n) => n.id === id),
    );

    const findNotes = (ids: number[]) =>
      [
        ...initialNotes.value,
        ...initialCompletedNotes.value,
        ...notesToTasks.value,
      ].filter((n) => ids.includes(n.id));

    const invertStatus = (status: NoteStatus) =>
      status === 'ACTIVE' ? 'CLOSE' : 'ACTIVE';

    const removeNoteFromArray = (arr: Ref<Note[]>, note: Note) => {
      const index = arr.value.findIndex((n) => n.id === note.id);
      if (index !== -1) arr.value.splice(index, 1);
    };

    const toggleStatus = async (ids: number[], withMoving = true) => {
      const foundNotes = findNotes(ids);
      if (!foundNotes.length) return;
      movingNotes.value = foundNotes;
      const initialStatus = movingNotes.value[0].status;
      movingNotes.value.forEach((note) => {
        note.status = invertStatus(initialStatus);
        totalNotes.value += initialStatus === 'CLOSE' ? 1 : -1;
        if (initialStatus === 'CLOSE') note.priorityNumber = totalNotes.value;
      });
      services.notes.update(movingNotes.value);
      const doUpdate = async () => {
        if (initialStatus === 'CLOSE') {
          movingNotes.value.forEach((note) => {
            removeNoteFromArray(initialCompletedNotes, note);
            initialNotes.value.push(note);
          });
        } else {
          movingNotes.value.forEach((note) => {
            removeNoteFromArray(initialNotes, note);
            removeNoteFromArray(notesToTasks, note);
            initialCompletedNotes.value.push(note);
          });
        }
        await nextTick();
        updatePriorityNumbers();
        movingNotes.value = [];
      };
      if (withMoving) setTimeout(() => doUpdate(), 500);
      else doUpdate();
    };

    return { isNoteMoving, toggleStatus };
  }

  return {
    notes,
    notesToTasks,
    completedNotes,
    isNoteMoving,
    workloadPercentage,
    isNotesLoading,
    isCompletedNotesLoading,
    totalNotes,

    fetchNotes,
    fetchCompletedNotes,
    updatePriorityNumbers,
    toggleStatus,
  };
});

export const useSelection = () => {
  const selection = ref<number[]>([]);

  const isSelected = computed(
    () => (id: number) => selection.value.includes(id),
  );

  return {
    selection,
    isSelected,
  };
};

function useNotes() {
  const notesToTasks = ref<Note[]>([]);
  const notes = ref<Note[]>([]);

  const totalNotes = ref<number>(0);

  const {
    state: initialNotes,
    execute: fetchNotes,
    isLoading: isNotesLoading,
  } = useAsyncState<Note[], [], false>(
    async () => {
      const { data } = await services.notes.getAll({
        status: 'ACTIVE',
        unpaged: true,
      });
      for (const note of notesToTasks.value) {
        const noteIndex = data.content.findIndex((n) => n.id === note.id);
        if (noteIndex === -1) continue;
        data.content.splice(noteIndex, 1);
      }
      data.content.sort((a, b) => a.priorityNumber - b.priorityNumber);
      totalNotes.value = data.totalElements;
      return data.content;
    },
    [],
    { immediate: false, shallow: false, resetOnExecute: false },
  );

  const { search, status } = storeToRefs(useNotesFilters());
  watch(
    () => [initialNotes.value.length, search.value, status.value],
    () => {
      if (status.value === 'CLOSE') {
        notes.value = [...initialNotes.value];
        return;
      }
      notes.value = initialNotes.value.filter(
        (note) =>
          (note.title.toLowerCase().startsWith(search.value.toLowerCase()) ||
            note.description
              .toLowerCase()
              .startsWith(search.value.toLowerCase())) &&
          !notesToTasks.value.find((n) => n.id === note.id),
      );
    },
  );

  return {
    initialNotes,
    notes,
    fetchNotes,
    isNotesLoading,
    totalNotes,
    notesToTasks,
  };
}

function useCompletedNotes() {
  const {
    state: initialNotes,
    execute: fetchCompletedNotes,
    isLoading: isCompletedNotesLoading,
  } = useAsyncState<Note[], [], false>(
    async () => {
      const { data } = await services.notes.getAll({
        status: 'CLOSE',
        unpaged: true,
      });
      return data.content;
    },
    [],
    { immediate: false, shallow: false, resetOnExecute: false },
  );
  const completedNotes = ref<Note[]>([]);

  const { search, status } = storeToRefs(useNotesFilters());
  watch(
    () => [initialNotes.value.length, search.value, status.value],
    () => {
      if (status.value === 'ACTIVE') {
        completedNotes.value = [...initialNotes.value];
        return;
      }
      completedNotes.value = initialNotes.value.filter(
        (note) =>
          note.title.toLowerCase().startsWith(search.value.toLowerCase()) ||
          note.description.toLowerCase().startsWith(search.value.toLowerCase()),
      );
    },
  );

  return {
    initialCompletedNotes: initialNotes,
    completedNotes,
    fetchCompletedNotes,
    isCompletedNotesLoading,
  };
}

function usePriorityNumbers(
  notes: Ref<Note[]>,
  initialNotes: Ref<Note[]>,
  notesToTask: Ref<Note[]>,
) {
  const handleList = (list: Note[], disabledIds: number[]) => {
    const notesToUpdate: Note[] = [];

    const numbers: number[] = [];
    let num = 1;
    while (numbers.length !== list.length) {
      if (!disabledIds.includes(num)) {
        numbers.push(num);
      }
      num++;
    }

    list.forEach((note, index) => {
      if (note.priorityNumber !== numbers[index]) {
        note.priorityNumber = numbers[index];
        notesToUpdate.push(note);
      }
    });

    return notesToUpdate;
  };

  return async () => {
    const notesToUpdate: Note[] = [];
    const globalDisabled = initialNotes.value
      .filter(
        (note) =>
          ![...notes.value, ...notesToTask.value].find((n) => n.id === note.id),
      )
      .map((n) => n.priorityNumber);

    [notes.value, notesToTask.value].forEach((list, index, arr) => {
      const disabledIds = [
        ...globalDisabled,
        ...arr
          .filter((_, nestedIndex) => index !== nestedIndex)
          .map((l) => l.map((n) => n.priorityNumber))
          .flat(),
      ];
      notesToUpdate.push(...handleList(list, disabledIds));
    });

    if (!notesToUpdate.length) return;
    try {
      await services.notes.update(notesToUpdate);
    } catch (e) {
      console.error(e);
    }
  };
}
