import services from '@/api/services';
import { TASK_DAY_FORMAT } from '@/consts/timeFormats';
import {
  NotesByCalendarDay,
  NotesByWorkspace,
} from '@/stores/notes/useNotesByCalendar';
import { useUserStore } from '@/stores/user';
import { useWorkspaceStore } from '@/stores/workspaces';
import { Task } from '@/types/tasks';
import { isNote } from '@/utils/note';
import {
  endOfDay,
  format,
  startOfDay,
  addDays,
  eachDayOfInterval,
} from 'date-fns';
import { Ref, computed, ref, watch } from 'vue';

export const useNoteCalendarGrouping = (groups: Ref<NotesByWorkspace[]>) => {
  const currentGroup = ref<NotesByWorkspace | null>(null);
  const tasks = ref<Task[]>([]);
  const calendar = ref<NotesByCalendarDay[]>([]);

  const isLastWorkspace = computed(
    () =>
      groups.value.findIndex(
        (g) => g.workspace.id === currentGroup.value?.workspace.id,
      ) ===
      groups.value.length - 1,
  );

  const getTasks = async () => {
    tasks.value = [];
    const today = new Date();
    const {
      data: { content },
    } = await services.tasks.fetchTasks({
      pageNum: 1,
      pageSize: 100,
      dateFrom: format(startOfDay(today), 'yyyy-MM-dd HH:mm'),
      dateTo: format(endOfDay(addDays(today, 6)), 'yyyy-MM-dd HH:mm'),
      taskDTO: {
        executorIdList: [],
        priorityList: [],
        statusList: [],
        taskAssignment: null,
        taskFastFilters: null,
        taskNameSearch: '',
      },
    });
    tasks.value = content;
    return content;
  };

  const isDatesEqual = (date1?: string, date2?: string) =>
    format(new Date(date1 || ''), 'yyyy-MM-dd') ===
    format(new Date(date2 || ''), 'yyyy-MM-dd');

  const refreshCalendar = () => {
    const group = currentGroup.value;
    if (!group) return;
    const today = new Date();
    const dates = eachDayOfInterval({
      start: today,
      end: addDays(today, 7),
    });
    calendar.value = dates.map((date) => {
      const stringDate = format(date, 'yyyy-MM-dd');
      return {
        date: stringDate,
        items: [
          ...tasks.value.filter((task) =>
            isDatesEqual(task.deadline, stringDate),
          ),
          ...group.notes.filter((note) =>
            isDatesEqual(note.planner?.selectedDate, stringDate),
          ),
        ],
      };
    });
  };

  const isLoading = ref(false);
  const workspaceStore = useWorkspaceStore();
  const userStore = useUserStore();
  watch(currentGroup, async () => {
    calendar.value.forEach(({ date, items }) => {
      items.forEach((item) => {
        if (isNote(item) && item.planner) {
          item.planner.selectedDate = format(new Date(date), TASK_DAY_FORMAT);
        }
      });
    });
    if (!currentGroup.value) {
      calendar.value = [];
      tasks.value = [];
      return;
    }
    isLoading.value = true;
    await workspaceStore.setCurrent(currentGroup.value.workspace.id);
    await userStore.getUserData();
    await getTasks();
    refreshCalendar();
    isLoading.value = false;
  });

  const nextWorkspace = () => {
    if (!currentGroup.value) {
      currentGroup.value = groups.value[0] || null;
      return;
    }
    const index = groups.value.findIndex(
      (g) => g.workspace.id === currentGroup.value?.workspace.id,
    );
    if (index !== -1 && index !== groups.value.length - 1)
      currentGroup.value = groups.value[index + 1] || null;
    else return true;
  };

  return {
    calendar,
    currentGroup,
    nextWorkspace,
    isGroupLoading: isLoading,
    isLastWorkspace,
  };
};
