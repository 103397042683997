<template>
  <div class="file-item">
    <div class="upload-item" ref="imageRef">
      <el-image
        v-if="file.typeFile === 'PHOTO'"
        fit="contain"
        class="upload-item-content"
        :src="file.link"
        :preview-src-list="linksSet"
        :initial-index="initialPreviewId"
        hide-on-click-modal
      >
        <template #error>
          <div class="upload-item__not-image">
            <icon-copy color="#000" />
            <span>{{ file.name }}</span>
          </div>
        </template>
      </el-image>
      <div class="upload-item-content upload-item__not-image" v-else>
        <div class="upload-item__extension">
          <icon-create color="#778bee" />
          <span>{{ file.name.split('.').pop() }}</span>
        </div>
        <span>{{ file.name }}</span>
      </div>
    </div>
    <div class="upload-item__controls">
      <icon-trash
        class="upload-item__controls-item upload-item_trash-icon"
        @click="$emit('deleteImage', file.id)"
      />
      <icon-upload
        class="upload-item__controls-item"
        @click="$emit('uploadImage', file)"
      />
      <icon-view
        class="upload-item__controls-item"
        @focusin="onViewClick"
        v-if="file.typeFile === 'PHOTO'"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { GetFile } from '@/types/common';
import IconTrash from '@/components/icon/IconTrash.vue';
import IconUpload from '@/components/icon/IconUpload.vue';
import IconView from '@/components/icon/IconView.vue';

import IconCopy from '@/components/icon/IconCopy.vue';
import IconCreate from '@/components/icon/IconCreate.vue';

defineProps<{
  file: GetFile;
  linksSet: string[];
  initialPreviewId: number;
}>();

defineEmits(['deleteImage', 'uploadImage']);

const imageRef = ref<HTMLElement | null>(null);
const onViewClick = () => imageRef?.value?.querySelector('img')?.click();
</script>
<style lang="scss" scoped>
.file-item {
  position: relative;
  min-width: calc(33% - 1.5rem);
  box-shadow: 0 1rem 3rem -0.5rem rgba(169, 169, 169, 0.2);

  &:hover {
    .upload-item__controls {
      visibility: visible;
      opacity: 1;
    }
  }
}
.upload-item {
  position: relative;
  border-radius: 0.8rem;
  overflow: hidden;
  width: 100%;
  height: 10rem;
  min-width: 11.8rem;
  cursor: pointer;
  flex-grow: 1;
  transition: all 0.2s ease-in-out;

  &:hover {
    //border-bottom-right-radius: 0;
    //border-bottom-left-radius: 0;

    .ui-drop-files__preview-icon {
      opacity: 1;
    }

    .upload-item__controls {
      display: none;
    }
  }
  &-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__not-image {
    @include flex-column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    height: 100%;
    box-shadow: 0 10px 30px -5px rgba(169, 169, 169, 0.2);

    > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: inherit;
    }

    > svg {
      flex-shrink: 0;
      width: fit-content;
    }
  }

  &__extension {
    position: relative;

    > svg {
      width: 6rem;
      height: 6rem;
    }

    > span {
      position: absolute;
      width: 4rem;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: var(--color-primary);
    }
  }

  &__controls {
    display: flex;
    visibility: hidden;
    opacity: 0;
    align-items: center;
    justify-content: space-between;
    background: #f2f7f7;
    width: 100%;
    transform: translateX(-0.5%);
    position: absolute;
    bottom: -3.6rem;
    height: 3.6rem;
    padding: 1rem 1.5rem;
    border-bottom-right-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    z-index: 100;
    transition: all 0.2s ease-in-out;

    &-item {
      width: 2.5rem;
      height: 2.5rem;
      flex: 1;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

:deep(.upload-item__controls-item) {
  &:hover {
    path {
      stroke: var(--color-success);
    }
  }
}

:deep(.upload-item_trash-icon) {
  &:hover {
    path {
      fill: var(--color-danger);
      stroke: var(--color-danger);
    }
  }
}
</style>
