import { ref } from 'vue';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import services from '@/api/services';

export const useStripe = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  const publicKey = isProduction
    ? process.env.VUE_APP_PROD_STRIPE_PUBLISH_KEY
    : process.env.VUE_APP_DEV_STRIPE_PUBLISH_KEY;

  const stripeInstance = ref<Stripe | null>(null);
  const elements = ref<StripeElements>();
  const messages = ref<string[]>([]);
  const clientSecret = ref('');
  const paymentPending = ref(true);

  const stripeInit = async () => {
    try {
      stripeInstance.value = await loadStripe(publicKey);
      if (!stripeInstance.value)
        return messages.value.push('No Stripe instance');
    } catch (e) {
      console.error(e);
    }
  };

  const getClientSecret = async () => {
    paymentPending.value = true;
    try {
      const { data } = await services.purchase.getStripeFormSecret();
      clientSecret.value = data.clientSecret;
    } catch (e) {
      console.error(e);
    } finally {
      paymentPending.value = false;
    }
  };

  const createSetupElement = async () => {
    if (!stripeInstance.value) return;
    await getClientSecret();

    const options = {
      clientSecret: clientSecret.value,
    };

    elements.value = stripeInstance.value?.elements(options);
    const paymentElement = elements.value?.create('payment');
    paymentElement.mount('#payment-element');
  };

  const onSetup = async () => {
    let error = null;
    if (!elements.value || !stripeInstance.value || !clientSecret.value)
      return { error };

    await elements.value.submit();
    const data = await stripeInstance.value?.confirmSetup({
      elements: elements.value,
      clientSecret: clientSecret.value,
      redirect: 'if_required',
    });

    if (data.error?.message) {
      messages.value.push(data.error.message);
      error = data.error.message;
    }
    return { error };
  };

  const onSubscribe = async (paymentId: string) => {
    const result = await services.purchase.getSubscription(paymentId);
    console.log(result);
    return result;
  };

  const onTrialSubscribe = async (paymentId: string) => {
    const result = await services.purchase.getTrialSubscription(paymentId);
    console.log(result);
    return result;
  };

  const onCancelSubscribe = async (subscriptionId: string, reason: string) => {
    const result = await services.purchase.cancelSubscription(
      subscriptionId,
      reason,
    );
    console.log(result);
    return result;
  };

  return {
    stripeInit,
    createSetupElement,
    onSetup,
    onTrialSubscribe,
    onSubscribe,
    onCancelSubscribe,
    stripeInstance,
    paymentPending,
  };
};
