<template>
  <div class="widget-workspaces">
    <el-popover
      trigger="click"
      placement="bottom"
      width="30.5rem"
      popper-class="workspaces-manage"
      v-model:visible="isWidgetVisible"
      :style="coachElementsStyles['dashboard'][7]"
    >
      <div class="widget-workspaces__options options">
        <template v-if="ownWorkspacesList.length">
          <div class="options__row">
            <span class="disabled-text">{{ $t('workspaces.created') }}</span>
            <span
              style="cursor: pointer"
              class="options__edit"
              @click="
                isOwnEditOpen = true;
                isWidgetVisible = false;
              "
            >
              {{ $t('workspaces.edit_btn') }}
            </span>
          </div>
          <div :class="['options__list', { pending: widgetPending }]">
            <workspace-item
              v-for="item in ownWorkspacesList"
              :key="item.id"
              :item="item"
              :is-active="currentWorkspace?.id === item.id"
              @click="onChangeWorkspace(item.id)"
            />
          </div>
        </template>
        <template v-if="guestWorkspacesList.length">
          <div class="options__row">
            <span class="disabled-text">{{ $t('workspaces.guest') }}</span>
            <!--            <span-->
            <!--              style="cursor: pointer"-->
            <!--              class="options__edit"-->
            <!--              @click="-->
            <!--                isGuestEditOpen = true;-->
            <!--                isWidgetVisible = false;-->
            <!--              "-->
            <!--            >-->
            <!--              {{ $t('workspaces.edit_btn') }}-->
            <!--            </span>-->
          </div>
          <div :class="['options__list', { pending: widgetPending }]">
            <workspace-item
              v-for="item in guestWorkspacesList"
              :key="item.id"
              :item="item"
              :is-active="currentWorkspace?.id === item.id"
              @click="onChangeWorkspace(item.id)"
            />
          </div>
        </template>
        <div class="options__control">
          <div class="options__row" @click="onCreateWorkspace">
            <span>{{ $t('workspaces.create_btn') }}</span>
            <img src="@images/workspaces/new.svg" alt="" />
          </div>
          <div
            class="options__row"
            @click="
              isJoinOpen = true;
              isWidgetVisible = false;
            "
          >
            <span>{{ $t('workspaces.join_btn') }}</span>
            <img src="@images/workspaces/join.svg" alt="" />
          </div>
        </div>
      </div>
      <template #reference>
        <div
          :class="['widget-workspaces__reference', { active: isWidgetVisible }]"
        >
          {{ $t('workspaces.title') }}
          <div class="widget-workspaces__reference-current">
            <icon-marker :color="currentWorkspace?.color" />
            <span class="widget-workspaces__reference-name">
              {{ currentWorkspace?.name }}
            </span>
          </div>
          <icon-chevron :direction="isWidgetVisible ? 'top' : 'bottom'" />
        </div>
      </template>
    </el-popover>
    <modal-create-workspace
      :is-open="isCreateOpen"
      @close="isCreateOpen = false"
    />
    <modal-edit-workspaces
      :list="ownWorkspacesList"
      :is-open="isOwnEditOpen"
      @close="isOwnEditOpen = false"
    />
    <modal-edit-guest-workspaces
      :list="guestWorkspacesList"
      :is-open="isGuestEditOpen"
      @close="isGuestEditOpen = false"
    />
    <modal-join-workspace :is-open="isJoinOpen" @close="isJoinOpen = false" />
    <modal-plan-buy
      :title="$t('card_tariff.no_workspaces_label')"
      @close="isPlansModalVisible = false"
      :is-open="isPlansModalVisible"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import IconMarker from '@/components/icon/IconMarker.vue';
import WorkspaceItem from '@/components/workspaces/WorkspaceItem.vue';
import ModalCreateWorkspace from '@/components/modals/Workspaces/Create/ModalCreateWorkspace.vue';
import ModalEditWorkspaces from '@/components/modals/Workspaces/ModalEditWorkspaces.vue';
import ModalPlanBuy from '@/components/modals/Billing/ModalPlanBuy.vue';
import { useUserStore } from '@/stores/user';
import IconChevron from '@/components/icon/IconChevron.vue';
import ModalJoinWorkspace from '@/components/modals/Workspaces/ModalJoinWorkspace.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { useCoachesStore } from '@/stores/coaches/coaches';
import ModalEditGuestWorkspaces from '@/components/modals/Workspaces/ModalEditGuestWorkspaces.vue';

const workspacesStore = useWorkspaceStore();
const { setCurrent } = workspacesStore;
const { currentWorkspace, ownWorkspacesList, guestWorkspacesList } =
  storeToRefs(workspacesStore);

const coachesStore = useCoachesStore();
const { activeCoach, coachElementsStyles } = storeToRefs(coachesStore);

const userStore = useUserStore();

const tariffStore = useTariffStore();
const { workspacesLeft } = storeToRefs(tariffStore);

const isWidgetVisible = ref(false);
const isCreateOpen = ref(false);
const isOwnEditOpen = ref(false);
const isGuestEditOpen = ref(false);
const isJoinOpen = ref(false);
const widgetPending = ref(false);
const isPlansModalVisible = ref(false);

const onChangeWorkspace = async (id: number) => {
  try {
    widgetPending.value = true;
    await setCurrent(id);
    await userStore.getUserData();
  } catch (e) {
    console.error(e);
  } finally {
    widgetPending.value = false;
  }
};

const onCreateWorkspace = () => {
  workspacesLeft.value > 0
    ? (isCreateOpen.value = true)
    : (isPlansModalVisible.value = true);
  isWidgetVisible.value = false;
};

watch(
  () => activeCoach.value,
  () => {
    isWidgetVisible.value =
      activeCoach.value.activeStep === 7 &&
      activeCoach.value.coach === 'dashboard';
  },
  { deep: true },
);
</script>

<style scoped lang="scss">
.widget-workspaces {
  @include f14;

  &__reference {
    cursor: pointer;
    @include flex-row(1rem);
    align-items: center;
    padding: 1.3rem;
    border: 1px solid transparent;
    border-radius: 1rem;
    width: 30.5rem;
    white-space: nowrap;

    &.active {
      border-color: #dde3ec;
    }

    @include gray-bg-hover;

    & > svg {
      margin-left: auto;
    }

    &-current {
      @include flex-row(1rem);
      justify-content: center;
      align-items: center;
    }

    &-name {
      max-width: 12.5rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__options {
    padding: 1rem 0;
    width: 100%;
    min-width: 29rem;
  }
}

.options {
  @include flex-column(2rem);

  &__row {
    padding: 0.3rem 1rem;
    @include flex-row(1rem);
    align-items: center;
    justify-content: space-between;
  }

  &__edit {
    transition: color 0.15s ease-in-out;
    &:hover {
      color: var(--color-primary);
    }
  }

  &__control {
    cursor: pointer;
    padding-top: 2rem;
    border-top: 1px solid #dde3ec;
    @include flex-column(1rem);

    & > * {
      @include gray-bg-hover;
    }
  }

  &__list {
    @include flex-column;
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
    max-height: 20vh;
    overflow: auto;

    &.pending {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
