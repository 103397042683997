export const mappedRoleType = {
  URGENT: { background: '#FFDEDC', badge: '#EC7975' },
  LOW: { background: '#E7E7E7', badge: '#676E7B' },
  NORMAL: { background: '#E5F7FF', badge: '#70C9F4' },
  HIGH: { background: '#FFECD4', badge: '#F19F3C' },
  NOTIFICATION: { background: '#778BEE' },
};

export const taskPrioritySortMap = {
  URGENT: 1,
  HIGH: 2,
  NORMAL: 3,
  LOW: 4,
};
