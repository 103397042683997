<template>
  <el-dialog :model-value="isOpen" @close="$emit('close')" width="45rem">
    <template #header>
      <h3 class="workspaces-edit__header">
        {{ $t('workspaces.edit.guest-title') }}
      </h3>
    </template>
    <transition name="blur" appear mode="out-in">
      <div :class="['workspaces-edit', { pending }]">
        <div
          class="workspaces-edit__row"
          v-for="workspace in list"
          :key="workspace.id"
        >
          <icon-marker :color="workspace.color" />
          {{ workspace.name }}
          <div
            class="workspaces-edit__exit"
            @click="
              currentId = workspace.id;
              isExitVisible = true;
            "
          >
            Exit <icon-logout />
          </div>
        </div>
      </div>
    </transition>
    <template #footer><div /></template>
  </el-dialog>
  <modal-delete-workspace
    :is-open="isExitVisible"
    :title="$t('workspaces.exit.title')"
    :message="$t('workspaces.exit.message')"
  >
    <template #footer>
      <div class="notification-modal__btns">
        <ui-button plain @click="onExitWorkspace">
          <icon-logout style="max-height: 1.6rem" />
          {{ $t('workspaces.exit.confirm') }}
        </ui-button>
        <ui-button
          @click="
            isExitVisible = false;
            currentId = null;
          "
        >
          {{ $t('workspaces.exit.cancel') }}
        </ui-button>
      </div>
    </template>
  </modal-delete-workspace>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import UiButton from '@/components/control/UiButton.vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { Workspace } from '@/types/workspaces';
import IconMarker from '@/components/icon/IconMarker.vue';
import ModalDeleteWorkspace from '@/components/modals/Workspaces/ModalDeleteWorkspace.vue';
import IconLogout from '@/components/icon/nav/IconLogout.vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

type Props = {
  isOpen?: boolean;
  list: Workspace[];
};
defineProps<Props>();
defineEmits(['close']);

const { t } = useI18n();

const workspacesStore = useWorkspaceStore();
const { fetchList, removeUser } = workspacesStore;

const user = useUserStore();
const { userData } = storeToRefs(user);

const isExitVisible = ref(false);
const pending = ref(false);
const currentId = ref<number | null>(null);

const onExitWorkspace = async () => {
  if (!currentId.value) return;
  try {
    isExitVisible.value = false;
    pending.value = true;

    if (!userData.value?.id) return;
    await removeUser({
      userId: userData.value?.id,
      companyId: currentId.value,
    });
    await fetchList();
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};
</script>

<style scoped lang="scss">
.workspaces-edit {
  @include flex-column(2rem);
  align-items: center;
  padding: 2.5rem 3rem;
  width: 100%;
  height: 45vh;
  min-height: 30rem;
  overflow: auto;
  transition: all 0.15s ease-in-out;

  &.pending {
    opacity: 0.5;
    pointer-events: none;
  }

  &__header {
    padding-top: 3rem;
    @include f16;
  }

  &__row {
    width: 100%;
    @include flex-row(1rem);
    align-items: baseline;
  }

  &__exit {
    @include flex-row(0.6rem);
    align-items: center;
    justify-content: center;
    @include f16;
    @include gray-bg-hover;
    cursor: pointer;
    padding: 0.5rem;
    margin-left: auto;

    & > svg {
      width: 1.6rem;
    }
  }

  &__footer {
    padding: 1rem 3rem 3rem;

    & > * {
      margin-left: auto;
    }
  }
}
</style>
