import { capitalize } from '@/utils/text';
import { ISubordinate } from '@/types/subordinate';
import { i18n } from '@/plugins/i18n';

export const filtersMapping = (filter: string) => {
  const { t, te } = i18n.global;
  const label = capitalize(filter).replaceAll('_', ' ');
  const localePath = 'tasks.filters.values.' + filter;

  return {
    value: filter,
    label: te(localePath) ? t(localePath) : label,
  };
};

export const invitationsFiltersMapping = (filter: string) => {
  const { t, te } = i18n.global;
  const label = capitalize(filter).replaceAll('_', ' ');
  const localePath = 'invitations.statuses.' + filter;

  return {
    value: filter,
    label: te(localePath) ? t(localePath) : label,
    icon: require(`@images/invitation-statuses/${String(
      filter,
    ).toLowerCase()}.svg`),
  };
};

export const subordinatesFiltersMapping = ({
  firstName,
  email,
  lastName,
  id,
  avatar,
}: ISubordinate) => ({
  value: id,
  label: `${firstName || ''} ${lastName || ''}`.trim() || email || 'Undefined',
  avatarUrl: avatar?.uuidLink || null,
});
