import { invitationStatuses } from '@/consts';
import { TableFilter } from '@/types/common';
import { IInvitationFilters, TInvitationSorts } from '@/types/invitation';

import { tableSorting } from '@/utils/table';
import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { invitationsFiltersMapping } from '@/utils/filtersMapping';
import UiTableFiltersCheckboxList from '@/components/table/filters/UiTableFiltersCheckboxList.vue';

export const useInvitationsFilter = defineStore('invitationsFilter', () => {
  const { t } = useI18n();
  const initialFilters = (): IInvitationFilters => ({
    pageNum: 1,
    pageSize: 10,
    filter: '',
    filters: {
      status: [],
    },
  });
  const filters = ref<IInvitationFilters>(initialFilters());

  const sorts = reactive<TInvitationSorts>({
    columnSort: '',
    order: null,
  });

  const filtersList = computed<TableFilter[]>(() => [
    {
      options: Object.keys(invitationStatuses)
        .filter((s) => s !== 'CREATED')
        .map(invitationsFiltersMapping),
      label: t('tasks.filters.status'),
      filterField: 'status',
      component: UiTableFiltersCheckboxList,
    },
  ]);

  function setFilter<
    FilterField extends keyof (typeof filters.value)['filters'],
  >(field: FilterField, values: (typeof filters.value.filters)[FilterField]) {
    filters.value.pageNum = 1;
    filters.value.filters[field] = values;
  }

  function setSort(columnSort: TInvitationSorts['columnSort']) {
    tableSorting<TInvitationSorts>(sorts, columnSort);
  }

  function setPage(page: number) {
    filters.value.pageNum = page;
  }

  function setPageSize(pageSize: number) {
    filters.value.pageSize = pageSize;
  }

  function refreshFilters() {
    filters.value = initialFilters();
  }

  return {
    setSort,
    filters,
    initialFilters,
    setFilter,
    refreshFilters,
    setPage,
    setPageSize,
    filtersList,
    sorts,
  };
});
