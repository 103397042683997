import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import LayoutDefault from '@/layouts/default.vue';
import LayoutUnsigned from '@/layouts/unsigned.vue';
import LayoutAuth from '@/layouts/auth.vue';
import LayoutApp from '@/layouts/app.vue';
import LayoutBooking from '@/layouts/booking.vue';
import UiInput from '@/components/control/UiInput.vue';
import UiButton from '@/components/control/UiButton.vue';
import UiInputUnderscore from '@/components/control/UiInputUnderscore.vue';
import { Calendar, DatePicker, setupCalendar } from 'v-calendar';
import vCalendarConfig from '@/configs/v-calendar';
import Element from 'element-plus';
import 'v-calendar/style.css';
import 'element-plus/dist/index.css';
import '@/assets/styles/index.scss';
import pinia from '@/stores';
import VClickOutside from '@/directives/v-click-outside';
import VSkeleton from '@/directives/v-skeleton';
import { i18n } from '@/plugins/i18n';
import { vMaska } from 'maska';
import { createHead } from '@vueuse/head';
import vue3GoogleLogin from 'vue3-google-login';
import VueAppleLogin from 'vue-apple-login';
import contenteditable from 'vue-contenteditable';

const isProduction = process.env.NODE_ENV === 'production';
const app = createApp(App);

app
  .directive('maska', vMaska)
  .directive('click-outside', VClickOutside)
  .directive('skeleton', VSkeleton)
  .component('tag-content-editable', contenteditable)
  .use(i18n)
  .use(pinia)
  .use(router)
  .use(Element)
  .use(setupCalendar, vCalendarConfig)
  .use(createHead())
  .use(vue3GoogleLogin, { clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID })
  .use(VueAppleLogin, {
    clientId: isProduction
      ? 'com.unionsmarttech.voisetapp'
      : 'com.unionsmarttech.voisetapp',
    scope: 'name email',
    redirectURI: isProduction
      ? 'https://voiset.org/login'
      : 'https://dev.voiset.org/login',
    state: 'secret_state_123',
    nonce: '234234234',
    usePopup: true,
  })
  .component('default-layout', LayoutDefault)
  .component('unsigned-layout', LayoutUnsigned)
  .component('auth-layout', LayoutAuth)
  .component('app-layout', LayoutApp)
  .component('booking-layout', LayoutBooking)
  .component('v-calendar', Calendar)
  .component('v-date-picker', DatePicker)
  .component('ui-input', UiInput)
  .component('ui-button', UiButton)
  .component('ui-input-underscore', UiInputUnderscore)
  .mount('#app');
