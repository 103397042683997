import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import axios from 'axios';
import { VoiceToken, VoiceKeyResponse } from '@/types/voice';

export class VoiceService extends ApiService {
  getVoiceApiKey(localeId: number | undefined) {
    return axiosInstance.get<VoiceKeyResponse>(ENDPOINTS.voice.getApiKey, {
      params: {
        localeId,
      },
    });
  }

  getSpeechToken(apiKey: string) {
    return axios.post<VoiceToken>(
      ENDPOINTS.voice.getSpeechToken,
      { ttl: 3600 },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      },
    );
  }

  finishRecord(tokenId: number, durationMilliSec: number) {
    return axiosInstance.get<any>(ENDPOINTS.voice.finishRecord, {
      params: {
        tokenId,
        durationMilliSec,
      },
    });
  }
}
