import { watchPausable } from '@vueuse/core';
import { ref, readonly, WatchSource } from 'vue';

export const useChanged = (
  cb: WatchSource<unknown> | WatchSource<unknown>[],
) => {
  const isChanged = ref(false);

  const { pause, resume } = watchPausable(
    cb,
    () => {
      isChanged.value = true;
      pause();
    },
    {
      deep: true,
      immediate: false,
    },
  );
  pause();

  const reset = () => {
    pause();
    isChanged.value = false;
  };

  const init = () => {
    reset();
    resume();
  };

  return {
    isChanged: readonly(isChanged),
    reset,
    init,
  };
};
