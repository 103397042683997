import { RouteRecordRaw } from 'vue-router';
const SettingsView = () => import('@/views/settings/settings.vue');
const SettingsIndex = () => import('@/views/settings/index.vue');
const SettingsIntegrationsView = () =>
  import('@/views/settings/integrations/integrations.vue');
const SettingsIntegrationsIndex = () =>
  import('@/views/settings/integrations/index.vue');
const SettingsIntegrationsCalendarsView = () =>
  import('@/views/settings/integrations/calendars/calendars.vue');
const SettingsIntegrationsCalendarsIndex = () =>
  import('@/views/settings/integrations/calendars/index.vue');
const SettingsIntegrationsCalendarsGoogle = () =>
  import('@/views/settings/integrations/calendars/google.vue');
const SettingsIntegrationsCommunicationView = () =>
  import('@/views/settings/integrations/communication/communication.vue');
const SettingsIntegrationsCommunicationIndex = () =>
  import('@/views/settings/integrations/communication/index.vue');
const SettingsIntegrationsCommunicationGoogle = () =>
  import('@/views/settings/integrations/communication/zoom.vue');
const SettingsIntegrationsBillingIndex = () =>
  import('@/views/billing/index.vue');

const communicationRoutes: RouteRecordRaw = {
  path: 'communication',
  meta: {
    breadCrumb: 'Communication',
  },
  name: 'SettingsIntegrationsCommunicationView',
  component: SettingsIntegrationsCommunicationView,
  children: [
    {
      path: '',
      name: 'SettingsIntegrationsCommunicationIndex',
      component: SettingsIntegrationsCommunicationIndex,
    },
    {
      path: 'zoom',
      meta: {
        breadCrumb: 'Zoom',
      },
      name: 'SettingsIntegrationsCommunicationZoom',
      component: SettingsIntegrationsCommunicationGoogle,
    },
  ],
};

const calendarsRoutes: RouteRecordRaw = {
  path: 'calendars',
  meta: {
    breadCrumb: 'Calendars',
  },
  name: 'SettingsIntegrationsCalendarsView',
  component: SettingsIntegrationsCalendarsView,
  children: [
    {
      path: '',
      name: 'SettingsIntegrationsCalendarsIndex',
      component: SettingsIntegrationsCalendarsIndex,
    },
    {
      path: 'google',
      meta: {
        breadCrumb: 'Google',
      },
      name: 'SettingsIntegrationsCalendarsGoogle',
      component: SettingsIntegrationsCalendarsGoogle,
    },
  ],
};

export default {
  path: '/settings',
  component: SettingsView,
  meta: {
    layout: 'app',
  },
  children: [
    {
      path: '',
      name: 'Settings',
      component: SettingsIndex,
    },
    {
      path: 'integrations',
      name: 'SettingsIntegrations',
      redirect: { name: 'SettingsIntegrationsIndex' },
      component: SettingsIntegrationsView,
      meta: {
        layout: 'default',
      },
      children: [
        {
          path: '',
          name: 'SettingsIntegrationsIndex',
          redirect: { name: 'SettingsIntegrationsCalendarsIndex' },
          component: SettingsIntegrationsIndex,
          children: [
            communicationRoutes,
            calendarsRoutes,
            {
              path: 'billing',
              meta: { breadCrumb: 'Billing' },
              component: SettingsIntegrationsBillingIndex,
            },
          ],
        },
      ],
    },
  ],
} as RouteRecordRaw;
